import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useApi } from '@api';
import { ROOT_EVENTS } from '@app/reducers';
import { LoaderBackdrop } from '@components/Loader';
import { useAuth } from '@components/AuthProvider/AuthProvider';
import config from '@config/config';
import UserInfo from '@components/Login/UserInfo';
import useAccount from '@api/queries/useAccount';

const LoginCallBack = (): JSX.Element | null => {
  const history = useHistory();
  const api = useApi();
  const { loginWithCallback } = useAuth();
  const dispatch = useDispatch();

  const isAuthenticated = api.storeService.getAccessToken();
  const { data: accountData, isFetching: isAccLoading, error } = useAccount();

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }
    loginWithCallback();
    if (accountData?._id || !error) {
      return;
    }

    const loginFallbackUrl = config.getOemValue('LOGIN_FALLBACK_URL');
    if (loginFallbackUrl) {
      window.location.replace(loginFallbackUrl);
    } else {
      history.push({ pathname: '/login' });
    }
  }, []);

  if (error && accountData?._id) {
    dispatch({ type: ROOT_EVENTS.DESTROY_SESSION });
    return null;
  }

  const sxmAccessTokenUrl = config.getOemValue('SXM_ACCESS_TOKEN_URL');
  const isInternalIdp = !sxmAccessTokenUrl;
  const redirectToVinStatus = config.getOemValue('DISPLAY_VIN_STATUS_PAGE');

  if (isAccLoading || !isAuthenticated || !accountData?._id) {
    return <LoaderBackdrop />;
  }

  if (redirectToVinStatus) {
    return <Redirect to="/vinStatus" />;
  }

  if (isInternalIdp) {
    return <Redirect to="/" />;
  }

  return <UserInfo />;
};

export default LoginCallBack;
