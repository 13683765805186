import React, { useMemo } from 'react';
import { EligiblePackageInfo, PackageSubscription } from '../Types';
import { Container } from '@styled-components/globalStyles';
import { ContinueButton, ContinueButtonsWrapper, ExpirationImage } from './styles';
import { PromoPackages } from '../Packages';
import { LegalContainer } from '../Legal';
import { hasPackageAdded } from '../utils';
import { DiscountContent, DiscountExpiryHeader, DiscountExpirySection } from '../Packages/DiscountPackage/styles';
import { CommonWebContent, PromoPackagesWebContent, TrialPackagesWebContent } from '..';
import { PromoPackagesProps } from '../Packages/DiscountPackage/PromoDiscount/PromoPackages';
import PackageSection from '@manageSubscription/Packages/PaidPackage/PackageSection';

export type ManagePromoPackageListProps = {
  promoPackages: EligiblePackageInfo[];
  commonWebContent: CommonWebContent;
  promoPackagesWebContent: PromoPackagesWebContent;
  trialPackagesWebContent: TrialPackagesWebContent;
  isTrialEligible?: boolean;
  canShowBackButton: boolean;
  packageSubscriptions: PackageSubscription[];
  navigate: (isSelected: boolean) => void;
  navigateBack?: () => void;
  disable?: boolean;
  maxSelectedPackages?: number;
} & PromoPackagesProps;

const ManagePromoPackageList = ({
  promoPackages,
  commonWebContent,
  promoPackagesWebContent,
  trialPackagesWebContent,
  isTrialEligible,
  canShowBackButton,
  packageSubscriptions,
  navigate,
  disable,
  navigateBack,
  ...promoPackagesProps
}: ManagePromoPackageListProps) => {
  const { backButtonLabel, continueButtonLabel, saveAndContinueButtonLabel, skipAndContinueButtonLabel } =
    commonWebContent;
  const { header, description, legalText, expiryHeader, expiryDescription } = promoPackagesWebContent;
  const { trialEligibleHeader, trialEligibleSubheader, packageSelectionTrialTermsDisclaimer } = trialPackagesWebContent;

  const promoHeader = isTrialEligible ? trialEligibleHeader : header;
  const subHeader = isTrialEligible ? trialEligibleSubheader : description;

  const isPromoPackageSelected = useMemo(
    () => promoPackages?.some((pkg) => hasPackageAdded(packageSubscriptions, pkg)),
    [promoPackages, packageSubscriptions],
  );

  const legal =
    isTrialEligible && packageSelectionTrialTermsDisclaimer ? packageSelectionTrialTermsDisclaimer : legalText;

  return (
    <Container>
      {promoPackages?.length > 0 ? (
        <PackageSection header={promoHeader} subHeader={subHeader}>
          <PromoPackages
            promoPackages={promoPackages}
            commonWebContent={commonWebContent}
            promoPackagesWebContent={promoPackagesWebContent}
            packageSubscriptions={packageSubscriptions}
            {...promoPackagesProps}
          />
        </PackageSection>
      ) : (
        <DiscountExpirySection>
          <DiscountExpiryHeader>{expiryHeader}</DiscountExpiryHeader>
          <DiscountContent>
            <ExpirationImage assets={promoPackagesProps.assets} name="Expiration" />
            <span>{expiryDescription}</span>
          </DiscountContent>
        </DiscountExpirySection>
      )}
      <ContinueButtonsWrapper>
        <ContinueButton onClick={() => navigate(isPromoPackageSelected)} disabled={disable && !isPromoPackageSelected}>
          {promoPackages?.length > 0
            ? isPromoPackageSelected
              ? saveAndContinueButtonLabel
              : skipAndContinueButtonLabel
            : continueButtonLabel}
        </ContinueButton>
        {canShowBackButton && (
          <ContinueButton variant="outlined" onClick={navigateBack}>
            {backButtonLabel}
          </ContinueButton>
        )}
      </ContinueButtonsWrapper>

      <LegalContainer legalText={legal} showCheckbox={false} />
    </Container>
  );
};

export default ManagePromoPackageList;
