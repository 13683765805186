import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { SalesChannel } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { getCookie } from '@utils/cookies';
import { TokenTypes } from '@api/services/StoreService';
import { useConfig } from '@components/ConfigProvider';
import useVehicle from '@api/queries/useVehicle';
import useAccount from '@api/queries/useAccount';
import useEligiblePackages from '@api/queries/useEligiblePackages';
import useSubscribedPackages from '@api/queries/useSubscribedPackages';
import { fetchPromoCodePackages } from '@api/requests/subscription/fetchPromoCodePackages';
import { useApi } from '@api';
import { RootState } from '@app/reducers';
import urlSearchParams from '@utils/urlSearchParams';
import { RRS_SOURCES } from '@components/SubscriptionWrapper/constants';
import milliseconds from 'date-fns/milliseconds';
import { SUBSCRIPTION_PAGE_URL } from '@components/App/UrlParser';

export type PromoCodePackagesQueryOptions = {
  onSuccess?: () => void;
};

const usePromoCodePackages = ({ onSuccess = () => undefined }: PromoCodePackagesQueryOptions = {}) => {
  const accessToken = getCookie(TokenTypes.ACCESS_TOKEN) ?? '';
  const config = useConfig();
  const locale = useSelector(({ settingsReducer }: RootState) => settingsReducer?.locale || 'en-US');

  const { data: vehicle } = useVehicle();
  const { data: account } = useAccount();

  const {
    data: { eligiblePackages },
  } = useEligiblePackages();

  const {
    data: { subscribedPackages },
  } = useSubscribedPackages();

  const api = useApi();

  const discountCriteria = {
    promoCode: api.storeService.getPromoCode(),
    ...(api.storeService.getPromoCodeCriteria() || {}),
  };

  const {
    sxmKeyDetails,
    flowName,
    tenantId = getCookie('b'),
    salesChannel,
  } = urlSearchParams.getAll<{
    sxmKeyDetails: ISxmKey;
    u?: string;
    v?: string;
    flowName: string;
    sxmkey: string;
    tenantId?: string;
    userEmail: string;
    pkgIdsToRemove?: string[];
    salesChannel: SalesChannel;
  }>();

  const getSalesChannel = () => {
    if (RRS_SOURCES.includes(sxmKeyDetails?.source)) {
      return SalesChannel.Rapid_registration;
    }
    if (flowName === 'savemore') {
      return SalesChannel.SubscriberDiscount;
    }
    if (salesChannel) {
      return salesChannel;
    }
    return SalesChannel.Subscriber;
  };

  const duringPromoCodeFlow = flowName === 'promocode' && window.location.pathname === SUBSCRIPTION_PAGE_URL;
  const dataIsFetched = !!vehicle?.vehicleId && !!account?._id && eligiblePackages?.length > 0;

  const enabled = config.get('USE_IS_PIN_CONFIGURED_IN_PROMO_PREVIEW')
    ? duringPromoCodeFlow && dataIsFetched && account?.isPinConfigured
    : duringPromoCodeFlow && dataIsFetched;

  return useQuery({
    enabled,
    queryKey: ['promoCodePackages', vehicle?.vehicleId, account?._id],
    queryFn: () =>
      fetchPromoCodePackages({
        accessToken,
        environment: config.getEnvironmentEnum(),
        locale,
        userId: account?._id,
        vehicleId: vehicle?.vehicleId,
        salesChannel: getSalesChannel(),
        tenantId: tenantId || sxmKeyDetails?.tenantId,
        location: flowName,
        eligiblePackages,
        subscribedPackages,
        discountCriteria,
      }),
    placeholderData: [],
    staleTime: milliseconds({ minutes: 10 }),
    onSuccess,
  });
};

export default usePromoCodePackages;
