import { previewOrder } from '@cv/portal-cps-lib';
import { SalesChannel } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { Environment } from '@cv/portal-common-lib/ajax/constants';
import { getSalesChannel } from '@app/components-lib/services/cps';
import { TenantIds } from '@app/components-lib/manageSubscription/utils/productFlowMap';
import { FlowLocation } from '@app/components-lib/Router/flowMiddleware';
import { packagesToAdd, packagesToRemove } from '@app/components-lib/manageSubscription/utils';
import { PackageSubscription, SubscribedPackageInfo } from '@customTypes/PackageInfo';
import { PackageToRemoveBuilderType } from '@app/components-lib/manageSubscription/Types';

export type MakePreviewOrderOptions = {
  accessToken: string;
  environment: Environment;
  selectedPackages: PackageSubscription[];
  subscribedPackages: SubscribedPackageInfo[];
  userId: string;
  vehicleId: string;
  tenantId: TenantIds;
  salesChannel: SalesChannel;
  transactionInfo: unknown;
  paymentMethodId?: string;
  packageToRemoveBuilder?: PackageToRemoveBuilderType;
  promoCode?: string;
  locale?: string;
  location?: FlowLocation;
};

export const makePreviewOrder = ({
  accessToken,
  environment,
  selectedPackages,
  subscribedPackages,
  userId,
  vehicleId,
  tenantId,
  location,
  salesChannel,
  paymentMethodId,
  promoCode,
  transactionInfo,
  packageToRemoveBuilder = packagesToRemove,
  locale = 'en-US',
}: MakePreviewOrderOptions) => {
  const request = {
    environment,
    headers: {
      Authorization: accessToken,
      'Accept-Language': locale,
      'CV-Tenant-Id': tenantId,
    },
    data: {
      userId,
      vehicleId,
      salesChannel: getSalesChannel(salesChannel, tenantId, location),
      retention: true,
      preview: true,
      paymentMethodId,
      promoCodes: promoCode ? [promoCode] : null,
      transactionInfo,
      packagesToAdd: packagesToAdd(selectedPackages),
      packagesToRemove: packageToRemoveBuilder(subscribedPackages, selectedPackages),
    },
  };
  return previewOrder(request).then((res) => res.data);
};
