import { PreviewOrderResponse } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { SalesChannel } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { Environment } from '@cv/portal-common-lib/ajax/constants';
import { makePreviewOrder } from '@api/requests/subscriptionFlow/makePreviewOrder';
import { getPackagesMatchingCriteria } from '@app/components-lib/manageSubscription/utils/packageUtils';
import { TenantIds } from '@app/components-lib/manageSubscription/utils/productFlowMap';
import {
  buildPreviewOrderRequestInfo,
  buildPackageSubscriptions,
  buildPackagesWithDiscount,
} from '@app/components-lib/manageSubscription/builders';
import { DiscountInfo, EligiblePackageInfo, SubscribedPackageInfo } from '@app/components-lib/manageSubscription/Types';
import { FlowLocation } from '@app/components-lib/Router/flowMiddleware';

export type FetchPromoCodePackagesProps = {
  accessToken: string;
  environment: Environment;
  locale?: string;
  userId: string;
  vehicleId: string;
  tenantId: TenantIds;
  salesChannel: SalesChannel;
  eligiblePackages: EligiblePackageInfo[];
  discountCriteria: DiscountInfo;
  subscribedPackages: SubscribedPackageInfo[];
  paymentMethodId?: string;
  location: FlowLocation;
};

export const fetchPromoCodePackages = async ({
  accessToken,
  environment,
  locale,
  userId,
  vehicleId,
  tenantId,
  location,
  salesChannel,
  paymentMethodId,
  eligiblePackages,
  discountCriteria,
  subscribedPackages,
}: FetchPromoCodePackagesProps) => {
  const promoCodePackages = getPackagesMatchingCriteria(eligiblePackages, discountCriteria);
  const { packagesToAdd, packagesToRemove, discountInfo } = buildPreviewOrderRequestInfo(
    buildPackageSubscriptions(promoCodePackages),
    subscribedPackages,
    discountCriteria,
  );

  const previewPromoCodePackages = packagesToAdd.map((eligiblePkg) =>
    makePreviewOrder({
      accessToken,
      environment,
      locale,
      userId,
      vehicleId,
      tenantId,
      location,
      salesChannel,
      transactionInfo: null,
      selectedPackages: [eligiblePkg],
      subscribedPackages: packagesToRemove,
      paymentMethodId,
      promoCode: discountInfo?.promoCode,
    }),
  );

  const result = await Promise.allSettled(previewPromoCodePackages);
  const previewPromoCodeResponses = result
    .filter(({ status }) => status === 'fulfilled')
    .map((response: PromiseFulfilledResult<PreviewOrderResponse>) => response.value);

  return buildPackagesWithDiscount(packagesToAdd, previewPromoCodeResponses);
};
