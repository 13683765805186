import React, { ReactNode } from 'react';
import { scroller } from 'react-scroll';
import { Box, Link, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { WebContentProps } from '@components/VinLookup/Types';

interface VinLookupProps extends WebContentProps {
  children: ReactNode;
}

const VinLookup = ({ labels, assets, children }: VinLookupProps) => {
  const {
    vinLookupHeader,
    vinLookupSubheader,
    whatIsVinLabel,
    whatIsVinSectionId,
    whereToFindVinLabel,
    whereToFindVinSectionId,
  } = labels;
  const headerBackground = assets?.find((a) => a.key === 'vin-lookup-header-background');

  const scrollToSection = (sectionId: string) => {
    scroller.scrollTo(sectionId, {
      duration: 2500,
      smooth: 'easeInOutQuint',
    });
  };

  return (
    <Box
      data-testid="header-box"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '1.8em',
        backgroundPosition: 'center',
        backgroundImage: `url("${headerBackground?.asset.file.url}")`,
        zIndex: -1,
        backgroundSize: { xs: 'cover', md: 'cover' },
      }}
    >
      <Box
        sx={{
          padding: 4,
          width: { xs: '100%', md: '70%' },
          margin: 'auto',
          zIndex: 1,
        }}
      >
        <Typography variant="h3" mb={1} color="white">
          {vinLookupHeader}
        </Typography>
        <Typography variant="h6" color="white">
          {vinLookupSubheader}
        </Typography>

        {children}

        <Grid container spacing={2} alignItems="center">
          <Grid xs={6} md={8} onClick={() => scrollToSection(whatIsVinSectionId)}>
            <Link href="#" sx={{ '&:hover': { textDecoration: 'none', color: 'white' }, color: 'white' }}>
              {whatIsVinLabel}
            </Link>
          </Grid>
          <Grid
            xs={6}
            md={4}
            sx={{ textAlign: { xs: 'right', md: 'left' } }}
            onClick={() => scrollToSection(whereToFindVinSectionId)}
          >
            <Link href="#" sx={{ '&:hover': { textDecoration: 'none', color: 'white' }, color: 'white' }}>
              {whereToFindVinLabel}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default VinLookup;
